body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  /* font-family: 'Roboto', 'Open Sans', sans-serif; */
  font-family: 'Sofia Pro';
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.btn-primary {
  color: white!important;
  background:#337E7D;
  border: 0px solid #5c9a97!important;
  padding: 9px;
}
.btn-primary:hover { 
  background: #40C1AC;
}
.btn-primary:active, .btn-primary:focus, .btn-primary:active:focus{ 
  background:#337E7D;
  box-shadow:0px 0px 0px 2px #0072CE inset;
}
.btn-primary.disabled, .btn-primary.disabled.active, .btn-primary.disabled.focus, .btn-primary.disabled:active, .btn-primary.disabled:focus, .btn-primary.disabled:hover, .btn-primary[disabled], .btn-primary[disabled].active, .btn-primary[disabled].focus, .btn-primary[disabled]:active, .btn-primary[disabled]:focus, .btn-primary[disabled]:hover, fieldset[disabled] .btn-primary, fieldset[disabled] .btn-primary.active, fieldset[disabled] .btn-primary.focus, fieldset[disabled] .btn-primary:active, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary:hover { background: #DDDDDD; color: #818181!important; border-color:#dad7d7!important; }
.btn-third { background-color: #e9e9e9; border:1px solid #e9e9e9; color: #666; }
.btn-third:hover { background: #dfdede; color: #464a4c;  }
.btn-third:active { background:#dfdede;  color: #464a4c; box-shadow: "inset 0px 1px 3px 0px rgba(0,0,0,0.50)";}
.btn-green { background-color: #009b96; border:1px solid #009b96; color: white; }
.btn-green:hover { background: #337E7D; color: white;  }
.btn-green:active { background:#337E7D;  color: white; box-shadow: "inset 0px 1px 3px 0px rgba(0,0,0,0.50)";}

.btn-third span { color: #666; }
.btn-danger { background-color: #e05061!important; border-color:#e05061!important; color: white!important;}
.btn-danger2 {
  background-color: white;
  border-color: #e05061!important;
  color: #e05061!important;
  /* #212529 */
}
.btn-xs {
padding: 0.4rem 0.5rem;
font-size: .675rem;
line-height: 1.5;
border-radius: .2rem;
}
.miljodirlogotext {
  font-weight: bold;
  font-family: 'Palatino Linotype', Georgia, 'Times New Roman', Times, serif;
  font-size: 20px;
  color: #006964;
  line-height: 30px;
  text-align: left;
}

.bigtext {
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;

  /* MD-Colors/MD- Sjøgrønn Mellom */
  color: #337E7D;
}
.mediumtext {
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  white-space: nowrap;

  /* MD-Colors/MD- Sjøgrønn Mellom */
  color: #337E7D;
}
.mediumtextblack {
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 16px;

  color: #000000;
}
.fontweightnormal {
  font-weight: 400;
}
.smallmediumtextblack {
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  /* line-height: 14px; */

  color: #000000;
}

/* Scollbars */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.btn-back {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  position: sticky;
  /* width: 304px; */
  height: 40px;
  left: 8px;
  top: 0px;

  /* MD-Colors/White 100% */
  background: #FFFFFF;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  /* margin: 0px 0px; */
}
.btn-back:hover {
  background:#E6EFEF;
}

.btn-forward {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  position: static;
  /* width: 304px; */
  /* height: 24px; */
  left: 8px;
  top: 16px;

  /* MD-Colors/White 100% */
  background: #FFFFFF;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  /* margin: 8px 0px; */
  
  white-space: nowrap;
  border-bottom: 2px solid #E6EFEF;
}
.btn-forward:hover {
  /* MD-Colors/MD- Sjøgrønn Lys */
  color:#40C1AC;
  border-bottom: 2px solid #40C1AC;
}
.btn-forward:focus, .btn-forward:active, .btn-forward:focus:active {
  box-shadow:0px 0px 0px 1px #0072CE inset;
}