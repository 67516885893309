#outerContainer {
    height: 100%;
    background-color: #fff;
    /* position: absolute; */
    /* width: 100%; */
    font-size: smaller;
    border-bottom: 0px !important;
    display:flex;
    flex-direction: row;
    /* align-items: center;
    justify-content: space-between; */
}
#filterContainer {
    /* height: 80px; */
    background-color: #fff;
    background: #f4fcfc;
    /* position: absolute; */
    width: 350px;

    font-size: smaller;
    border-bottom: 0px !important;
    /* padding: 0px; */
    /* padding:8px; */
    
    /* display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; */
    overflow-y: auto;
}
.filterContainerInner {
    padding: 8px;
    height: calc(100vh - 130px);
    overflow-y: auto;
}
.opacity-1 {
    opacity: 1 !important;
}
.white {
    color:#fff;
}
.textblack {
    color: #000;
}
.main-background {
    background: #f0f0f0;
}
.inner-background {
    background-color: #fff;
}
.border-radius-t {
    border-radius: 10px 10px 0 0;
}
.border-radius-br {
    border-radius: 0 0 10px 0;
}
.border-radius-bl {
    border-radius: 0 0 0 10px;
}
.halffilter {
    padding: 12px !important;
}
.halffilterleft {
    margin-left: -5px;
    margin-right: 5px;
}
.halffilterright {
    margin-left: 5px;
    margin-right: -5px;
}
.halffilterrow {
    margin-top: 2px;
    margin-left: 0px;
    margin-right: 0px;
}
.text-right {
    text-align: right !important;
}
.text-left {
    text-align: left !important;
}
.text-center {
    text-align: center !important;
}
.filterbutton {
    /* position: absolute; */
    bottom: 0px;
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 12px;
    margin-top: 12px;
    width: 100%;
}
.mt-1px {
    margin-top: 1px;
}
.mb-1px {
    margin-bottom: 1px;
}
.mb-2px {
    margin-bottom: 2px;
}
.lh-38px {
    line-height: 38px;
}
.lh-52px {
    line-height: 52px;
}
.spinnerWrap {
    text-align: center;
    margin: 0;
    margin-top: -3px;
    background-color: rgba(255,255,255,0.9);
    height: 100%;
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 3000;
    display: table-column;
    vertical-align: middle;
}
.spinnerMiddle {
    position: absolute;
    left: calc(50% - 80px);
    top: calc(50% - 100px);
}
.collapsetrigger {
    /* width: 100%; */
    height: 36px;
    left: 8px;
    top: 392px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    /* MD-Colors/MD- Sjøgrønn mørk 10% */
    background: #E6EFEF;
}
.collapsetrigger:hover {
    background: #F2F2F2;
    /* box-shadow:0px 0px 0px 2px #0072CE inset; */
}
.collapsetrigger:focus, .collapsetrigger:active, .collapsetrigger:focus:active{
    box-shadow:0px 0px 0px 2px #0072CE inset;
}
.Collapsible {
    padding-top: 8px; 
}
.searchContainer {
    position: fixed;
    bottom: 0px;
    padding: 8px;
    width: 350px;
    /* width: 44%;  */
    /* height: 70px;  */
}
/* .maintabpanel {
    /* height: calc(100vh - 75px); */
    /* padding: 8px; */
/* } */
.mr-2 {
    margin-right: 0.5rem!important;
}
.ml-2 {
    margin-left: 0.5rem!important;
}
.beigebackground {
    background-color: #FCFAF6;
}
.icon-sm-p {
    margin: 0.5rem!important;
}
.icon-sm {
    display: inline-block;
    width: 8.65px;
    height: 14px;
    background-size: cover;
    /* margin: 0.5rem; */
  }
.icon-forward {
    background-image: url(../images/forwardarrow.svg);
}
.btn-forward:hover .icon-forward {
    background-image: url(../images/forwardarrowgreen.svg);
}
.btn-forward:hover .icon-sm-p {
    margin-right: 16px !important;
}
.arealinput {
    border: none;
    border-bottom: 2px solid #005E5D;
    font-size: 14px;
    height: 30px;
    width: 35%;
    text-align: center;
}
.arealinput:focus {
    border: none;
    outline: none;
    border-bottom: 2px solid #40C1AC;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}
.arealdivider {
    height: 2px !important;
    color: #005E5D;
    opacity: 1;
}
.valgtfilter {
    white-space: nowrap;
    font-size: 14px;
    text-align: center;
}
.arealrow {
    justify-content: center;
}
.merknadsfeltrow {
    justify-content: center;
}
.merknadsfeltinput {
    border: none;
    border-bottom: 2px solid #005E5D;
    font-size: 14px;
    height: 30px;
    width: 85%;
    /* text-align: center; */
}
.merknadsfeltinput:focus {
    border: none;
    outline: none;    
    border-bottom: 2px solid #40C1AC;
}
.kodecontainer {
    display: flex;
    background:#E6EFEF;
    align-items: center;
    height: 40px;
    padding: 8px;
    font-size: 14px;
    line-height: 14px;
    border-bottom-style: solid;
    border-bottom-color: #005E5D;
    border-bottom-width: 4px;
}
.custommultivalueremove {
    padding-left: 4px;
    padding-right: 4px;
    color: black;
}
.custommultivalueremove:hover {
    background-color: #FFBDAD;
    color: #DE350B;
}
.form-check {
    height: 40px;
    width: 40px;
    /* background-color: #005E5D; */
    color: #005E5D;
    white-space: nowrap;
    line-height: 40px;
}
/* On mouse-over, add a grey background color */
.form-check:hover input {
    background-color: #ccc;
}

.form-check input  {
    height: 20px;
    width: 20px;
    margin:10px;
}
/* When the checkbox is checked, add a MDGREEN background */
.form-check input:checked  {
    background-color: #005E5D;
}
.janeicontainer {
    margin-left: 26px;
}
.toprowcontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
}