.resultContainer {
    height: calc(100vh - 95px);
    overflow-y: auto;
}
.datatable {
    width: 94%;
    overflow-x: hidden;
    margin-left: 40px;
}
.rdt_TableHead {
    background: #E6EFEF;
}
.rdt_TableHeadRow {
    background: #E6EFEF !important;
}
.rdt_TableCol {
    border: 1px solid #D6D8E7;
}
.rdt_TableCell {
    border: 1px solid #D6D8E7;
}
.rdt_TableRow {
    border-bottom: 0px !important;
}
.natstatlink {
    color: #005E5D;
}